@import "grid";

$mainColor: #158072;
$mainColorLight: #15807230;

*
{
	box-sizing: border-box;
	font-family: 'Nunito', sans-serif;
}

body
{
	margin: 0 !important;
	background: #000501;
	overflow-y: hidden;
}

.canvas-container
{
	padding: 5px;
	background-color: #000501 !important;

	canvas.animate
	{
		animation: fadeIn;
		animation-duration: 300ms;
	}
}

.hover-opacity
{
	transition: 200ms all linear;
	opacity: 1;
	cursor: pointer;

	&:hover
	{
		opacity: 0.8;
	}
}

.round-button
{
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border: 2px solid #ffffff;
	outline: none;
	background: #18191a;
	cursor: pointer;
	transition: all 150ms linear;

	&.selected, &:hover
	{
		-webkit-box-shadow: 0px 0px 20px 1px rgba(255,255,255,0.3);
		-moz-box-shadow: 0px 0px 20px 1px rgba(255,255,255,0.3);
		box-shadow: 0px 0px 20px 1px rgba(255,255,255,0.3);
	}

	&.selected
	{
		background: white;
		border-color: #18191a;

		svg path
		{
			fill: #18191a !important;
		}
	}
}

.actions-container
{
	display: flex;
	flex-direction: column;
	position: fixed;
	left: 20px;
	top: 20px;
	z-index: 5;

	button
	{
		margin-bottom: 10px;
	}
}


.share-container
{
	& > button, .share-box
	{
		position: fixed;
		right: 20px;
		bottom: 20px;
	}

	& > button
	{
		width: 60px;
		height: 60px;
		border: 5px solid #fff;
		background-color: #25f7f0;
		color: white;

		& > svg
		{
			path
			{
				fill: #fff;
			}
		}

	}

	.loading-animation
	{
		transform: scale(0.2) translateZ(0);
		margin-top: 7px;
		margin-left: 6px;
	}

	.share-box
	{
		bottom: 20px;
		background: #25f7f0;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
		border-radius: 30px;
		padding: 14px 20px;
		border: 5px solid #fff;

		svg
		{
			vertical-align: -2px;

			&.fill-black path
			{
				fill: #000;
			}
		}

		input
		{
			color: black;
			background: none;
			border: none;
			display: inline-block;
			margin-right: 30px;
			padding: 0 3px;
			outline: none;
			min-width: 250px;
			font-size: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.loading-animation
		{
			position: static;
			transform: scale(0.1);
			margin: 0 !important;
			height: 16px !important;
			width: 16px !important;

			div
			{
				display: inline-block;
				position: static;
				box-shadow: 0 4px 0 0 #000 !important;
			}
		}
	}
}

.icon-button
{
	border: none;
	outline: none;
	background: none;
	padding: 5px;
	border-radius: 3px;
	margin-left: 5px;

	&:hover
	{
		background: $mainColorLight;
		svg
		{
			fill: $mainColor;
		}
	}

	svg
	{
		vertical-align: -1px;
	}
}

.tooltip
{
	padding: 5px 10px !important;
}

.not-found
{
	position: fixed;
	font-size: 50px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.context-menu
{
	background: #18191a;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 2px;
	width: 300px;
	padding: 10px 0;

	.context-menu-item
	{
		padding: 3px 15px;
		width: 100%;
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		transition: 100ms background-color linear;

		&.disabled
		{
			cursor: default;
			pointer-events: none;

			span
			{
				color: #999;
			}

			svg
			{
				path
				{
					fill: #999;
				}
			}
		}

		&:hover:not(.line-width):not(.corners-radius):not(.text-size):not(.dimensions)
		{
			background: #333;
		}

		&.line-width, &.corners-radius, &.text-size, &.dimensions
		{
			input, button
			{
				font-size: 10px;
				background: none;
				border: 1px solid #fff;
				color: #25f7f0;
				margin-left: 10px;
			}

			button
			{
				padding-top: 0;
				padding-bottom: 0;

				&.selected
				{
					color: #777;
					border-color: #777;
				}

				&:hover
				{
					cursor: pointer;
					background: #333;
				}
			}

			.input-label
			{
				font-size: 12px;
			}

			.input-wrapper
			{
				height: 20px;

				input
				{
					height: 18px;
					padding: 0;
					text-align: center;
					position: relative;
					top: -3px;
					border-radius: 0 !important;
				}
			}
		}

		span
		{
			font-size: 14px;
			line-height: 20px;
		}

		svg
		{
			path
			{
				fill: #25f7f0;
			}

			vertical-align: -5px;
			margin-right: 15px;
		}
	}
}

.text-secondary
{
	color: #999;
}

.modal
{
	background: #101010;
	color: white;
	padding: 20px;

	.modal-header
	{
		width: 100%;
		display: flex;
		justify-content: space-between;
		border-bottom: 3px solid #fff;
		padding-bottom: 10px;
		margin-bottom: 25px;

		.title
		{
			font-size: 24px;
		}

		.toggles span:not(.delimiter)
		{
			font-size: 14px;
			text-decoration: underline;

			&.selected
			{
				color: #25f7f0;
				text-decoration: none;
			}
		}
	}

	.modal-body
	{

	}

	.modal-actions
	{
		display: flex;
		justify-content: flex-end;

		.button
		{
			margin-left: 15px;
		}
	}
}

.form-group
{
	margin-bottom: 25px;
}

.form-control
{
	font-size: 16px;
	padding: 7px 15px;
	background: #101010;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 3px;
	outline: none;
}

.button
{
	padding: 7px 14px;
	font-size: 16px;
	background: #101010;
	border: 1px solid #fff;
	color: #fff;
	border-radius: 3px;

	&[disabled]
	{
		background-color: #7f7f7f !important;
		border-color: #7f7f7f !important;
		pointer-events: none;
	}

	&.primary
	{
		color: #101010;
		border-color: #25f7f0;
		background: #25f7f0;
	}
}

.mdc-drawer
{
	left: 0;
	top: 0;
}

.mdc-drawer__header
{
	line-height: 30px;
	min-height: unset !important;
	border-bottom: 1px solid #ccc;
	margin-bottom: 15px;
}

.premade-image-wrapper, .idea-image-wrapper
{
	padding: 15px;
	border: 1px solid #ffffff;
	border-radius: 3px;
	width: 100%;
	height: 200px;
	position: relative;

	.premade-image, .idea-image
	{
		max-width: 80%;
		max-height: 80%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.premade-mobile-preview
	{
		height: 150px;
		width: 80%;
		left: 50%;
		transform: translateX(-50%);
		position: absolute;
		top: 160px;
		z-index: 100;
	}
}

.premade, .idea
{
	max-width: 250px;

	.name
	{
		font-size: 16px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 200px;
	}

	.by
	{
		font-size: 12px;
	}
}

@keyframes fadeIn
{
	0% {opacity: 0}
	100% {opacity: 1}
}

@keyframes loading-animation
{
	0% {transform: rotate(0deg)}
	50% {transform: rotate(180deg)}
	100% {transform: rotate(360deg)}
}
.loading-animation div {
	position: absolute;
	animation: loading-animation 1s linear infinite;
	width: 160px;
	height: 160px;
	margin-left: -14px;
	margin-top: -2px;
	border-radius: 50%;
	box-shadow: 0 4px 0 0 #fff;
	transform-origin: 80px 82px;
}
.loading-animation {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.3);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.loading-animation div
{
	box-sizing: content-box;
}

.share-tooltip, .ideas-tooltip
{
	white-space: nowrap;

	span
	{
		font-size: 14px;
	}

	svg
	{
		width: 20px;
		height: 20px;
		margin-bottom: -5px;
	}
}

.logo
{
	cursor: default;
	color: #25f7f0;
	font-size: 20px;
	margin-bottom: 10px;
	margin-left: -5px;

	span
	{
		color: #eaff00;
	}
}

.loader
{
	.shadow
	{
		background: #18191a;
		opacity: 0.85;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}

	.loading-animation
	{
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: -15px;
		margin-top: -30px;
	}
}

.tooltip-anchor, .ideas-tooltip-anchor
{
	position: absolute;
	width: 0;
	height: 0;
	background: transparent;
}

.tooltip-anchor
{
	right: -15px;
	top: 210px;
}

.ideas-tooltip-anchor
{
	left: 0;
	top: 35px;
}

::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #101010;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #fff;
	border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #ccc;
}

.copy-premade-url
{
	position: absolute;
	right: 10px;
	bottom: 3px;
}


.mobile-preview
{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	z-index: 5;
	text-align: center;
	padding: 0 20px;

	.logo
	{
		font-size: 80px;
		font-weight: 300;
	}

	.text
	{
		color: #eaff00;
		font-size: 14px;
	}

	.link
	{
		position: absolute;
		padding: 0 20px;
		bottom: 20px;
		left: 0;

		.text
		{
			color: #fff;
		}
	}
}

.ideas-button
{
	position: fixed;
	right: 30px;
}

.remove-idea
{
	text-decoration: underline;
	margin-top: 10px;
	display: inline-block;
}

.zoom-indicator
{
	font-size: 12px;
	position: fixed;
	left: 25px;
	bottom: 30px;
	z-index: 100;
	color: #ffffff;
	opacity: 0;

	&.disappear-immediately
	{
		animation-duration: 0s;
	}
}

.fade-in
{
	animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-out
{
	animation:fade-out 1s ease-out both
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-out{0%{opacity:1}100%{opacity:0}}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}
